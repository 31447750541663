<template>
  <div id="slider">
    <div class="window">
     <el-carousel trigger="click"
                 arrow="never"
                >
      <el-carousel-item v-for="(item,index) in bannerData"
                        :key="index">
        <el-image style="width: 100%; height: 100%"
                  :src="item.image"
                  @click="click(item)"
                  :class="{'hover':item.link}"
                  fit="cover"></el-image>
      </el-carousel-item>
    </el-carousel>
    </div>
  </div>
</template>
<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ol,
ul {
  list-style: none;
}
#slider {
  text-align: center;
}
.window {
  position: relative;
  width: 884px;
  height: 368px;
  margin: 0 auto;
  overflow: hidden;
}
.container {
  display: flex;
  position: absolute;
}
.window /deep/.el-carousel__container {
    position: relative;
    height: 368px;
}
</style>
<script>
export default {
  name: "slider",
  props: {
    bannerData: {
      type: Array,
      default: () => []
    },
    banlength: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {

    };
  },
  methods: {
    click ({ link }) {
      window.open(link, "_blank")
    },
    
  }
};
</script>

