/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-29 15:02:44
 * @Module: 星探
 */
 <template>
  <div class="page">
    <el-container class="page-content">
      <el-main
        width="884px"
        class="page-content-l"
        style="padding:0px;overflow:hidden;"
      >
        <bannerSwiper :bannerData="bannerData" :banlength="banlength" />
        <!-- 星探收益 -->
        <div class="sy">
          <div class="stitle">
            <span>星探收益</span>
            <p>简单三步轻松躺赚收益</p>
          </div>
          <div class="con">
            <div class="one">
              <img src="@/static/starImg/1.png" alt="" mode="aspectFill" />
              <span>选择王牌运营</span>
            </div>
            <div class="rarrow">
              <img src="@/static/starImg/rarrow1.png" />
            </div>
            <div class="one">
              <img src="@/static/starImg/2.png" alt="" mode="aspectFill" />
              <span>向Ta推荐主播</span>
            </div>
            <div class="rarrow">
              <img src="@/static/starImg/rarrow1.png" />
            </div>
            <div class="one">
              <img src="@/static/starImg/3.png" alt="" mode="aspectFill" />
              <span>双方对接成功</span>
            </div>
          </div>
        </div>
        <kingSwiper :cardData="cardData" />
        <ss :tecData="tecData" />
        <!-- <sort /> -->
      </el-main>
      <el-aside width="340px" class="page-content-r">
        <loginmodule />
        <topup />
        <attention />
        <rightTable :xtAccount="xtAccount" />
      </el-aside>
    </el-container>
  </div>
</template>
 <script>
import loginmodule from "@/views/login/loginmodule";
import topup from "@/views/homepage/rightmodule/topup";
import attention from "@/views/homepage/rightmodule/attention";
import rightTable from "@/views/star/item/rightTable";
// import sort from "@/views/star/item/sort";
import ss from "@/views/star/item/swiper";
import kingSwiper from "@/views/star/item/kingSwiper";
import bannerSwiper from "@/views/star/item/bannerSwiper";
export default {
  data() {
    return {
      cardData: [], //王牌运营列表
      tecData: [], //星探技巧列表
      bannerData: [], //banner列表
      banlength: 3, //banner长度
      xtAccount: {} //主播/余额数据
    };
  },
  components: {
    attention,
    topup,
    loginmodule,
    rightTable,
    // sort,
    ss,
    kingSwiper,
    bannerSwiper
  },
  created() {
    this.getKingData();
    this.getTecData();
    this.getBanner();
    this.getAccount();
  },
  methods: {
    async getKingData(params = {}) {
      const { data, status } = await this.$api.kingListApi(params);
      if (status == 200) {
        console.log(data);
        this.cardData = data;
      }
    },
    async getTecData(params = {}) {
      const { data, status } = await this.$api.xtTecApi(params);
      if (status == 200) {
        console.log(data);
        this.tecData = data;
      }
    },
    async getBanner(params = {}) {
      const { data, status } = await this.$api.bannerPcApi(params);
      if (status == 200) {
        console.log(data);
        this.bannerData = data;
        this.banlength = this.bannerData.length;
      }
    },
    async getAccount(params = {}) {
      const { data, status } = await this.$api.accountApi(params);
      if (status == 200) {
        console.log(data);
        this.xtAccount = data;
      }
    }
  }
};
</script>
 <style lang="scss" scoped>
.page {
  background: #f7f7f7;
  min-height: 100vh;
  border: solid 1px #f7f7f7;
}
.page-content {
  width: 1240px;
  margin: 0 auto;
  margin-top: 80px;
  // &-l {
  //   box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  // }
  &-r {
    margin-left: 16px;
  }
}
.banner {
  width: 100%;
  height: 368px;
  img {
    width: 100%;
    height: 100%;
  }
}
.stitle {
  height: 56px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-right: 10px;
  }
  p {
    font-size: 14px;
    color: #999999;
    margin-top: 2px;
  }
}
.sy {
  .con {
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: space-around;
    .one {
      width: 250px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      img {
        width: 26px;
        height: 26px;
        margin-right: 3px;
      }
    }
  }
}
.rarrow {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
</style>