<template>
  <!-- 
	*以数据驱动的card式展示图片（无动画效果）
	*  -->
  <div>
    <div class="title">
      星探技巧
    </div>
    <div class="cardBanner">
      <ul>
        <li
          v-for="(item, index) in tecData"
          :key="index"
          @click="goTec(item.uuid)"
        >
          <div class="Img">
            <img :src="item.moment_content.images[0]" alt="" />
          </div>
          <div class="dd">
            <div class="desc">
              {{ item.moment_content.title }}
            </div>
          </div>
        </li>
        <div class="arrow-left" @click="toggleFun(-1)">
          <img src="@/static/starImg/lslide.png" alt="" />
        </div>
        <div class="arrow-right" @click="toggleFun(1)">
          <img src="@/static/starImg/rslide.png" alt="" />
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tecData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    //通过函数改变数据从而达到视图的改变
    toggleFun(p) {
      this.tecData = this.tecData.map((item, index, array) => {
        if (index === array.length - 1 && p === 1) {
          item = array[0];
        } else if (index === 0 && p === -1) {
          item = array[array.length - 1];
        } else {
          item = array[index + p];
        }
        return item;
      });
    },
    goTec(id) {
      const { href } = this.$router.resolve({
        path: "/homepage/articleDetail",
        query: {
          uuid: id
        }
      });
      window.open(href, "_blank"); //打开新的窗口
    }
  }
};
</script>

<style lang="scss" scoped>
.cardBanner {
  padding: 10px 30px;
  position: relative;
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 60px;
  line-height: 60px;
  margin-left: 10px;
}
.cardBanner ul {
  display: flex;
  overflow: scroll; /*设置滚动条*/
}
.cardBanner ul::-webkit-scrollbar {
  /*隐藏滚动条*/
  display: none;
}
.cardBanner ul > li {
  width: 31.33333%;
  flex-shrink: 0;
  margin-right: 20px;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
.cardBanner ul > li:first-child {
  padding-left: 0;
}
.cardBanner ul > li a {
  display: block;
  width: 100%;
  height: 100%;
}
.cardBanner ul > li .Img {
  width: 262px;
  height: 146px;
  border-radius: 5px;
  img {
    width: 100%;
    height: 100%;
  }
}
.cardBanner ul > li .dd {
  height: 68px;
  width: 262px;
  display: flex;
  align-items: center;
  .desc {
    height: 44px;
    font-size: 15px;
    color: #333333;
    white-space: normal !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 0 10px;
    line-height: 22px;
  }
}
.arrow-left,
.arrow-right {
  width: 15px;
  height: 32px;
  img {
    width: 100%;
    height: 100%;
  }
}
.arrow-left {
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -17px;
}
.arrow-right {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -17px;
}
</style>